<template>
    <v-slide-group
        tag="nav"
        role="navigation"
        class="main-menu-slider-group"
        show-arrows
    >
        <v-slide-group-item
            v-for="(item, index) in navItems || []"
            :key="item.id || `nav-item-${index}`"
        >
            <MainMenuItem
                :item="item"
                :background-color="backgroundColor"
                :is-touch="isTouch"
            ></MainMenuItem>
        </v-slide-group-item>
    </v-slide-group>
</template>

<script lang="ts" setup>
    import type { MenuItem } from '~/types';
    import MainMenuItem from '~/components/header/menus/MainMenuItem/MainMenuItem.vue';
    import { useIsMobile } from '~/composables';

    withDefaults(
        defineProps<{
            navItems: Nilable<MenuItem[]>;
            backgroundColor?: Nilish<string>;
        }>(),
        {
            backgroundColor: null
        }
    );

    const { isTouch } = useIsMobile();
</script>

<style lang="scss"></style>
