<template>
    <v-btn
        class="top-menu-btn"
        :class="{ 'opacity-100': isHighlighted }"
        :color="isHighlighted ? 'secondary' : 'secondary-lighten-1'"
        variant="plain"
        density="comfortable"
    >
        <slot />
    </v-btn>
</template>

<script lang="ts" setup>
    const props = withDefaults(
        defineProps<{
            isActive: boolean;
        }>(),
        {
            isActive: false
        }
    );

    const route = useRoute();
    const attrs = useAttrs();

    const isCurrent = computed<boolean>(() => {
        const p = route.path;
        const to = String(attrs.to);
        return p.startsWith(to) || p.startsWith(to.replace(/^\/search/, ''));
    });

    const isHighlighted = computed<boolean>(() => props.isActive || isCurrent.value);
</script>

<style lang="scss"></style>
